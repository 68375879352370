// Enable certain polyfills depending on which browsers you need to support
//import 'core-js/es6';
//import 'core-js/es/reflect';
//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
//import 'reflect-metadata';

import 'zone.js';
import '@angular/localize/init';

interface IPolyFillErrorConstructor extends ErrorConstructor {
    stackTraceLimit: any;
}

if (!ENV) {
    var ENV = 'development';
}

if (ENV === 'production') {
    // Production
} else {
    // Development

    (<IPolyFillErrorConstructor>Error).stackTraceLimit = Infinity;
    // require('zone.js/dist/long-stack-trace-zone');
}
